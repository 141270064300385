$occupants-width: 990px;

.occupants {
  @media (min-width: $screen-md-min) {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: $screen-lg-min) {
    margin-left: auto;
    margin-right: auto;
    max-width: 990px;
    width: 86.086956522%;
  }
}

.occupants-group {
  border: 1px solid $panel-default-border;
  border-radius: $panel-border-radius;

  & + .occupants-group {
    margin-top: $grid-gutter-width * 2;
  }

  @media (min-width: $screen-md-min) {
    width: calc(50% - 5px);

    & + .occupants-group {
      margin-top: 0;
    }
  }
}

.occupants-group-heading {
  background-color: $gray-lighter;
  border-bottom: 2px solid $brand-success;
  @include border-top-radius(($panel-border-radius - 1));
  padding: 25px 15px 1px;
  text-align: center;
}

.occupants-group-title {
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 30px;
}

.occupants-group-actions {
  font-size: 0;
  padding: 0 0 37px;

  > p {
    border: 1px solid transparent;
    display: inline-block;
    font-size: $font-size-base;
    line-height: $line-height-base;
    margin: 0;
    padding-bottom: $padding-base-vertical;
    padding-top: $padding-base-vertical;
  }

  @media (min-width: $screen-sm-min) {
    .btn + .btn {
      margin-left: $grid-gutter-width;
    }
  }
}

.occupants-group-body {
  padding: 28px 15px 32px;

  @media (min-width: $screen-sm-min) {
    margin-left: auto;
    margin-right: auto;
    width: 52%;
  }

  @media (min-width: $screen-md-min) {
    width: 84%;
  }

  @media (min-width: $screen-lg-min) {
    width: 78.278688525%;
  }
}

.occupants-list-individuals {
  list-style: none;
  margin: 0 0 14px;
  padding: 0;

  > li {
    line-height: 1.5;

    &.occupants-list-icon {
      padding-left: 24px;
      position: relative;

      &:before {
        content: '\e008';
        display: block;
        left: 0;
        font-family: 'Glyphicons Halflings';
        position: absolute;
        text-align: left;
        top: 0;
        width: 20px;
      }

      &.occupants-list-link:before {
        color: $link-color;
      }
    }
  }
}

.occupants-list-leases {
  list-style: none;
  margin: 0;
  padding: 0;

  > li + li {
    margin-top: 10px;
  }

  p {
    color: $text-muted;
    font-size: 87%;
    margin: 0 0 4px;
  }

  p + p {
    margin-bottom: 0;
  }

  .occupants-list-individuals {
    margin-bottom: 0;
  }
}
