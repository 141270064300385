a {
  transition: color .5s;

  &.icon-link:hover,
  &.icon-link:focus {
    text-decoration: none;

    & span:not(.glyphicon) {
      text-decoration: underline;
    }
  }
}

@media(min-width: $screen-md-min) {
  .pos-md-abs {
    position: absolute;
  }
}
