
.visible-xxs-block,
.visible-xxs-inline,
.visible-xxs-inline-block {
  display: none !important;
}

@media (max-width: $screen-xxs-max) {
  @include responsive-visibility('.visible-xxs');
}
.visible-xxs-block {
  @media (max-width: $screen-xxs-max) {
    display: block !important;
  }
}
.visible-xxs-inline {
  @media (max-width: $screen-xxs-max) {
    display: inline !important;
  }
}
.visible-xxs-inline-block {
  @media (max-width: $screen-xxs-max) {
    display: inline-block !important;
  }
}
