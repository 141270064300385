.icon-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  .alert p.mg-lg + &,
  .alert p.mg-lg-top + & {
    margin-top: -4px;
  }

  > li {
    margin-bottom: $line-height-computed/3;
    padding-left: 1.5em;
    position: relative;

    .alert &:last-child {
      margin-bottom: $line-height-computed/2;
    }

    > .glyphicon:first-child {
      display: block;
      left: 0;
      position: absolute;
      top: 3px;
    }
  }
}
