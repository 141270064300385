.btn-group,
.btn-group-vertical {
  &.btn-group-block {
    display: block;
  }

  .btn {
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
    @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);

    &.btn-xs {
      @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
    }

    font-weight: 400;

    &.active,
    &.active:hover,
    &.active:focus,
    &.active.focus {
      background-color: $gray-lighter;
      box-shadow: none;
      font-weight: bold;
    }
  }

  & + .form-control-feedback {
    display: inline-block;
    height: 33px;
    line-height: 33px;
    position: static;
    vertical-align: bottom;
  }
}

.btn-group-link {
  & + .form-control-feedback {
    &:not(.glyphicon-remove):not(.glyphicon-ok) {
      width: 0;
    }

    &.glyphicon-remove,
    &.glyphicon-ok {
      & ~ .btn-link {
        padding-left: 0;
      }
    }
  }
}
