.list-photos {
  
  .photo-gallery {
    font-size: 0;
    margin-top: 25px;
  }

	.photos__loading {
    font-size: $font-size-base;
    font-style: italic;
    font-weight: normal;    
    text-transform: none;

    p {
      display: inline;
      vertical-align: middle;

      @media (max-width: $screen-sm-min) {
        display: none;
      }
    }

    span {
      color: $brand-primary;
      display: inline-block;
      font-size: 20px;
      vertical-align: middle;
    }
	}

  .photos__instructions {
    padding-left: 15px;
  }

  .list-photo-thumb {
    display: inline-block;
    font-size: $font-size-base;
    margin: 10px;
    position: relative;
    width: calc(20% - 20px);

    &:first-child {
      &:before {
        content: "Main Photo";
        color: $brand-success;
        display: inline-block;
        position: absolute;
        text-align: center;
        top: -25px;
        width: 100%;
      }
      .thumb {
        border: 2px solid $brand-success;
      }

      .thumb-label {
        color: $brand-success;
      }
    }

    @media (max-width: $screen-sm-min) {
      height: 188px;
      width: calc(50% - 20px);
    }    

    .delete-photo {
      color: $brand-danger;
      cursor: pointer;
      font-size: $font-size-small;
      position: absolute;
      top: 3px;
      right: 3px;
    }

    .thumb {
      border: 1px solid #e0e0e0;
      height: 148px;

      .thumb-img {
        display: block;
        height: 108px;
        margin: 20px;
      }

      @media (max-width: $screen-sm-min) {
        height: 148px;
      }  
    }

    .thumb-label {
      padding: 5px;
      text-align: center;
    }

    &.add-more {
      cursor: pointer;

      .thumb {        
        color: $gray-light;
        display: table;
        // height: 100%;
        font-size: 48px;
        margin: 0;
        text-align: center;
        width: 100%;

        .thumb-icon {
          display: table-cell;
          vertical-align: middle;
        }
      }

      .thumb-label {
        font-size: $font-size-base;
      }

    }
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }

}

.spin {
  animation: spin 1500ms infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}