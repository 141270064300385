.record {
  .alert + & {
    margin-top: $line-height-computed * 1.5;
  }

  & + .record:not(.record-mg-sm),
  & + .legend {
    margin-top: $line-height-computed * 2.5;
  }

  & + .record-mg-sm {
    margin-top: $line-height-computed/2;
  }

  .media {
    margin-bottom: $line-height-computed;
  }

  .media-object {
    max-width: 80px;
  }

  @media print {
    page-break-inside: avoid;

    & + .legend {
      margin-top: 0;
    }

    .media {
      page-break-inside: avoid;
    }

    .media-left {
      width: .75in;
    }

    .media-left ~ .media-body {
      width: 6.75in;
    }
  }
}
