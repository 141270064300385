// https://github.com/flatlogic/awesome-bootstrap-checkbox

//
// Checkboxes
// --------------------------------------------------


$font-family-icon: 'Glyphicons Halflings' !default;
$check-icon: "\e013" !default;

@mixin checkbox-variant($parent, $color) {
  #{$parent} input[type="checkbox"]:checked + label,
  #{$parent} input[type="radio"]:checked + label {
    &::before {
      background-color: $color;
      border-color: $color;
    }
    &::after{
      color: #fff;
    }
  }
}

@mixin checkbox-variant-indeterminate($parent, $color) {
  #{$parent} input[type="checkbox"]:indeterminate + label,
  #{$parent} input[type="radio"]:indeterminate + label {
    &::before {
      background-color: $color;
      border-color: $color;
    }
    &::after{
      background-color: #fff;
    }
  }
}

.abc-checkbox{
  padding-left: 23px;

  &:first-of-type {
    margin-top: 5px;
  }

  label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 7px;

    &::before{
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 21px;
      height: 20px;
      left: -23px;
      top: 0;
      border: 1px solid $input-border;
      border-radius: 3px;
      background-color: #fff;
      @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
    }

    &::after{
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 20.25px;
      height: 20px;
      left: -23px;
      top: 0;
      border: 1px solid transparent;
      font-size: 12px;
      line-height: 1.5;
      text-align: center;
      color: $input-color;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    &:focus + label::before {
      $color-rgba: rgba(red($input-border-focus), green($input-border-focus), blue($input-border-focus), .6);
      outline: 0;
      @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba);
    }

    &:checked + label::after{
      font-family: $font-family-icon;
      content: $check-icon;
    }

    .styled:checked + label {
      &::before {
        color: #fff;
      }
      &::after {
        color: #fff;
      }
    }

    &:indeterminate + label::after{
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px;
    }

    &:disabled + label{
      opacity: 0.65;

      &::before{
        background-color: $input-bg-disabled;
        cursor: not-allowed;
      }
    }

  }

  &.abc-checkbox-circle label::before{
    border-radius: 50%;
  }

  &.checkbox-inline{
    margin-top: 0;
  }

  .description {
    display: inline-block;
    line-height: 22.8571px;
    min-height: 22px;
    padding-left: 7px;
    position: relative;
    vertical-align: middle;
  }
}

@include checkbox-variant('.abc-checkbox-primary', $brand-primary);
@include checkbox-variant('.abc-checkbox-danger', $brand-danger);
@include checkbox-variant('.abc-checkbox-info', $brand-info);
@include checkbox-variant('.abc-checkbox-warning', $brand-warning);
@include checkbox-variant('.abc-checkbox-success', $brand-success);


@include checkbox-variant-indeterminate('.abc-checkbox-primary', $brand-primary);
@include checkbox-variant-indeterminate('.abc-checkbox-danger', $brand-danger);
@include checkbox-variant-indeterminate('.abc-checkbox-info', $brand-info);
@include checkbox-variant-indeterminate('.abc-checkbox-warning', $brand-warning);
@include checkbox-variant-indeterminate('.abc-checkbox-success', $brand-success);

//
// Radios
// --------------------------------------------------

@mixin radio-variant($parent, $color) {
  #{$parent} input[type="radio"],
  #{$parent} input[type="checkbox"]{
    + label{
      &::after{
        background-color: $color;
      }
    }
    &:checked + label{
      &::before {
        border-color: $color;
      }
      &::after{
        background-color: $color;
      }
    }
  }
}

.abc-radio{
  padding-left: 20px;

  &:first-of-type {
    margin-top: 5px;
  }

  label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before{
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 1px solid $input-border;
      border-radius: 50%;
      background-color: #fff;
      @include transition(border 0.15s ease-in-out);
    }

    &::after{
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 3px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $input-color;
      transform: scale(0, 0);

      transition: transform .1s cubic-bezier(.8,-0.33,.2,1.33);
      //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
    }
  }

  input[type="radio"],
  input[type="checkbox"]{
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    &:focus + label::before {
      $color-rgba: rgba(red($input-border-focus), green($input-border-focus), blue($input-border-focus), .6);
      outline: 0;
      @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba);
    }

    &:checked + label::after{
      transform: scale(1, 1);
    }

    &:disabled + label{
      opacity: 0.65;

      &::before{
        cursor: not-allowed;
      }
    }

  }

  &.radio-inline{
    margin-top: 0;
  }
}

@include radio-variant('.abc-radio-primary', $brand-primary);
@include radio-variant('.abc-radio-danger', $brand-danger);
@include radio-variant('.abc-radio-info', $brand-info);
@include radio-variant('.abc-radio-warning', $brand-warning);
@include radio-variant('.abc-radio-success', $brand-success);
