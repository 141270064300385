.list-group-item-text {
  padding-bottom: 2px;

  &+.list-group-item-text {
    margin-top: 4px;
  }

  .list-group-item-actions~.btn-group {
    bottom: 8px;
    position: absolute;
    right: 15px;
  }

  .badge {
    font-size: 12px;
    margin-right: 0.2em;
    vertical-align: text-bottom;
  }
}

.list-group-item-actions {
  font-size: 87%;
  margin: 0;
  padding: 0;

  li {
    display: inline;
    list-style: none;

    &:not(:last-child):after {
      content: '\00a0\00a0|\00a0';
    }

    a:focus {
      outline: none;
    }
  }
}

.list-group-item {
  &.list-group-compact-select {
    padding-right: 96px;

    .btn-group {
      right: 15px;
      position: absolute;
      top: 10px;
    }
  }

  .sortable & {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTc5MiIgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGw9IiMwMDAiIGZpbGwtb3BhY2l0eT0iMC4yIiBkPSJNMTc5MiA4OTZxMCAyNi0xOSA0NWwtMjU2IDI1NnEtMTkgMTktNDUgMTl0LTQ1LTE5LTE5LTQ1di0xMjhoLTM4NHYzODRoMTI4cTI2IDAgNDUgMTl0MTkgNDUtMTkgNDVsLTI1NiAyNTZxLTE5IDE5LTQ1IDE5dC00NS0xOWwtMjU2LTI1NnEtMTktMTktMTktNDV0MTktNDUgNDUtMTloMTI4di0zODRoLTM4NHYxMjhxMCAyNi0xOSA0NXQtNDUgMTktNDUtMTlsLTI1Ni0yNTZxLTE5LTE5LTE5LTQ1dDE5LTQ1bDI1Ni0yNTZxMTktMTkgNDUtMTl0NDUgMTkgMTkgNDV2MTI4aDM4NHYtMzg0aC0xMjhxLTI2IDAtNDUtMTl0LTE5LTQ1IDE5LTQ1bDI1Ni0yNTZxMTktMTkgNDUtMTl0NDUgMTlsMjU2IDI1NnExOSAxOSAxOSA0NXQtMTkgNDUtNDUgMTloLTEyOHYzODRoMzg0di0xMjhxMC0yNiAxOS00NXQ0NS0xOSA0NSAxOWwyNTYgMjU2cTE5IDE5IDE5IDQ1eiIvPjwvc3ZnPg==') no-repeat 10px 12px;
    background-size: 16px 16px;
    cursor: move;
    padding-left: 36px;

    &.sortable-chosen {
      background-color: $gray-lighter;

      &:not(.sortable-ghost) {
        background-color: #fff;
        border: 1px dashed $gray-dark !important;
        border-radius: $panel-border-radius - 1;
        margin-bottom: 0;
        position: relative;
      }
    }
  }
    /* Edric */
    .sort-able & {
      cursor: "all-scroll";
    }
}