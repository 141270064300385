body, html {
  height: 100%;
}
.page-main {
  background-color: #fff;
  min-height: calc(100% - 310px);
}

.vertical-center {
  display: table;
  width: 100%;
  height: 100%;

  .vertical-center-inner {
    display: table-cell;
    vertical-align: middle;
  }
}

.from-ad {
  background-color: $brand-list;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  margin: 0 2px;
  padding: 3px 8px;
}