/*
 ** Progress Bar Styles
 */

$progressbar-border: #d7d7d7;

.progressbar {
  position: relative;
  list-style: none;
  display: table;
  width: 100%;
  margin: 4px 0 31px;
  padding: 0;
  counter-reset: progressbar-counter 1;

  a {
    color: $progressbar-border;
  }

  li {
    display: table-cell;
    vertical-align: top;
  }

  li + li {
    counter-increment: progressbar-counter;
  }

  &.progressbar__3up li {
    width: 50%;
  }

  &.progressbar__3up li {
    width: 33.33%;
  }

  &.progressbar__4up li {
    width: 25%;
  }

  &.progressbar__5up li {
    width: 20%;
  }

  &.progressbar__6up li {
    width: 16.67%;
  }

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 20px;
    z-index: 0;
    height: 8px;
    background: $progressbar-border;
  }

  &.progressbar__2up:before {
    left: 25%;
    width: 50%;
  }

  &.progressbar__3up:before {
    left: 16.67%;
    width: 66.66%;
  }

  &.progressbar__4up:before {
    left: 12.5%;
    width: 75%;
  }

  &.progressbar__5up:before {
    left: 8.33%;
    width: 80%;
  }

  &.progressbar__6up:before {
    left: 10%;
    width: 83.33%;
  }

  li:first-child .progressbar-node {
    border-radius: 50% 0 50% 50%;
  }

  li:last-child .progressbar-node {
    border-radius: 50% 50% 50% 0;
  }

}

.progressbar-node {
  display: block;
  position: relative;
  width: 48px;
  height: 48px;
  margin: 0 auto 10px;
  padding: 4px;
  border-radius: 50% 0 50% 0;
  background: $progressbar-border;
  transform: translateZ(0) rotate(45deg);
  -ms-transform: rotate(45deg);

  &:before {
    content: counter(progressbar-counter);
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid white;
    background-clip: padding-box;
    background: white;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    color: $progressbar-border;
    transform: translateZ(0) rotate(-45deg);
    -ms-transform: rotate(-45deg);
  }  
}

.progressbar-completed {
  .progressbar-node:before {
    content: "\e013";
    background: $brand-primary;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-size: 22px;
    text-transform: none;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .progressbar-label a {
    color: $brand-primary;
    cursor: pointer;

    &:hover {
      text-decoration: underline;  
    }
  }
}

.progressbar-active {
  .progressbar-node:before {
    content: progressbar-counter;
    background: $brand-success;
    color: white;
  }

  .progressbar-label a {
    color: $brand-success;

    &:hover {
      cursor: pointer;
      text-decoration: underline;  
    }
    
  }
}

.progressbar-label {
  display: block;
  padding: 0 4px;
  text-align: center;
  font-size: $font-size-base;
  font-weight: bold;
  color: $gray-dark;

  a {
    cursor: default;
    text-decoration: none;
  }
}

.progressbar-completed .progressbar-label {
  color: $brand-success;
}
