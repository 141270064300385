.embed-responsive {
  &.embed-responsive-scrollable {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
  }
}

.embed-preview {
  background: $gray-lighter;
  border-radius: 0 0 ($panel-border-radius - 1) ($panel-border-radius - 1);
  overflow: hidden;
  position: relative;

  &:not(.embed-preview-loading) {
    min-height: 800px;
  }

  iframe {
    border: 0 none;
    bottom: $panel-border-radius - 1;
    display: block;
    height: calc(100% - #{$panel-border-radius - 1});
    left: 1px;
    overflow: hidden;
    position: absolute;
    right: 1px;
    top: 0;
    width: 100%;
  }
}

.embed-preview-instructions,
.embed-preview-actions {
  background: $gray-lighter;

  &.panel-body,
  &.modal-body {
    margin-bottom: -2px;
    position: relative;
    z-index: 50;

    @media(min-width: $screen-sm-min) {
      padding-bottom: 0;
    }

    @media(min-width: $screen-md-min) {
      margin-bottom: -12px;
    }
  }
}

.embed-preview-instructions {
  > .alert {
    margin: 9px auto 0;

    @media(min-width: $screen-sm-min) {
      margin-bottom: 2px;
      width: 652px;
    }

    @media(min-width: $screen-md-min) {
      margin-bottom: 9px;
      width: 708px;
    }
  }

  .editable {
    color: #00f;
    cursor: pointer;

    &.incomplete {
      background: red;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      font-weight: 700;
      letter-spacing: 3px;
      padding: 0 3px 0 6px;
    }
  }
}

.embed-preview-actions {
  text-align: center;

  @media(min-width: $screen-sm-min) {
    .btn + .btn {
      margin-left: 5px;
    }
  }
}
