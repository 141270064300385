// Tabs
// -------------------------

.nav-tabs {
  a {
    .glyphicon:last-child {
      font-size: 80%;
      margin-left: 8px;

      @media (min-width: $screen-lg-min) {
        font-size: 100%;
        margin-left: 10px;
      }
    }
  }

  > li {
    > a {
      margin-right: 1px;
      padding: 6px 9px;

      @media(min-width: $screen-lg-min) {
        padding: 10px 19px;
      }

      &:hover,
      &:focus {
        border-bottom-color: $nav-tabs-border-color;
      }
    }

    &.active > a {
      font-weight: bold;
      position: relative;

      &:after {
        background-color: #fff;
        bottom: -2px;
        content: '';
        height: 2px;
        left: 0;
        right: 0;
        position: absolute;
      }
    }
  }

  &.nav-tabs-collapse {

    > li.active {
      @media(max-width: $screen-xs-max) {
        min-width: 65%;
      }
    }

    > li:not(.active) {
      display: none;

      @media(min-width: $screen-md-min) {
        display: block;
      }
    }

    .dropdown {
      &.open {
        > a {
          border-color: transparent;
        }
      }

      .dropdown-menu {
        > li {
          position:  relative;

          &.active {
            display: none;
          }

          > a {
            padding-right: 40px;

            .glyphicon:last-child {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

  }

  @media print {
    display: none;
  }

}

.tab-content {
  padding: 19px 0 18px;

  @media only screen and (min-width: $screen-md-min) {
    border-bottom: solid 1px $nav-tabs-border-color;
    border-left: solid 1px $nav-tabs-border-color;
    border-right: solid 1px $nav-tabs-border-color;
    margin-top: -1px;
    padding-left: 9px;
    padding-right: 9px;
    position: relative;
    z-index: 5;
  }

  @media only screen and (min-width: $screen-lg-min) {
    padding-left: 19px;
    padding-right: 19px;
  }

  .tab-pane {
    > .alert:first-child {
      margin-top: 0;
    }

    > .alert:last-child {
      margin-bottom: 0;
    }

    > h4:first-child {
      margin-top: $line-height-computed;
    }

    > .mg-lg + .form-main {
      margin-top: $line-height-computed * 1.75;
    }

    > .form-main > .data-grid:last-child {
      margin-bottom: 0;
    }
  }
}

.nav-justified {
  @media (max-width: $screen-xs-max) {
    &.nav-justified-xs > li {
      display: table-cell;
      width: 1%;
      > a {
        border-radius: $border-radius-base $border-radius-base 0 0;
        margin-bottom: 0;
      }
    }
  }
}
