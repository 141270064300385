@mixin btn-link($color: $link-color, $hover: $link-hover-color, $disabled: $btn-link-disabled-color) {
  color: $color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: $hover;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $disabled;
      text-decoration: none;
    }
  }
}

.btn {
  transition: opacity .5s, background-color .5s, color .5s;

  .glyphicon:first-child:not(:last-child):not(.visible-xs) {
    margin-right: .45em;
  }

  .glyphicon-calendar {
    top: 3px;
  }

  .glyphicon.visible-xs {
    line-height: inherit;
  }

  &.btn-no-hrz-pad {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    white-space: normal;

    &:focus {
      outline:none;
    }
  }

  .input-group-btn & {
    // font-size: 15px;
  }

  @media (max-width: $screen-xxs-max) {
    &.btn-text-xxs {
      font-size: 13px;
      line-height: 1.758238462;
    }
  }
}

.btn-link-danger {
  @include btn-link($brand-danger, darken($brand-danger, 10%));
}

.btn-link-warning {
  @include btn-link($brand-warning, darken($brand-warning, 10%));
}

.btn-link-progress {
  @include btn-link($brand-progress, darken($brand-progress, 10%));
}

.btn-link-light {
  @include btn-link(#fff, darken(#fff, 10%));
}

.btn-wrap {
  white-space: normal;
}

.btn-right-xs {
  float: right;

  @media(min-width: $screen-sm-min) {
    display: block;
    float: none;
    width: 100%;
  }
}

@media(max-width: $screen-xs-max) {
  .btn-block-xs {
    display: block;
    float: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }

  .btn-block-xs + .btn-block-xs {
    margin-top: 5px;
  }
}
