.batch-message {
    font-size: 85%;
    color: #c82333;
    margin-top: 5px;
    display: none;
}

.d-inline-grid {
    display: inline-grid;
}
