.quick-links {
  margin-bottom: 26px;
  margin-top: 25px;

  @media (min-width: $screen-md-min) {
    margin-bottom: 32px;
    margin-top: 50px;
  }
}

.quick-link {
  background-color: #000;
  border-radius: $border-radius-large;
  margin: 0 0 ($line-height-computed / 2);
  position: relative;

  &:before {
    content: '';
    background: no-repeat center center;
    background-size: cover;
    border-radius: $border-radius-large;
    bottom: 0;
    display: block;
    left: 0;
    opacity: 0.33;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  a {
    border-radius: $border-radius-large;
    box-shadow: inset 0px 0px 36px 36px rgba(0,0,0,0.5);
    color: #fff;
    display: block;
    padding: 26px 10px 30px;
    position: relative;
    text-align: center;

    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }

    &,
    * {
      cursor: pointer;
    }

    h3 {
      font-weight: 700;
      margin: 0 0 9px;
      text-transform: uppercase;
    }

    p {
      font-family: $headings-font-family;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      margin-bottom: 28px;
    }

    .btn {
      border-color: #fff;
      border-width: 2px;
      font-size: 14px;

      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }

  &.quick-link-list {
    &:before {
      background-image: url('../img/quick-links/list.jpg');
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('../img/quick-links/list@2x.jpg');
      }
    }

    a {
      h3 {
        color: $brand-list;
      }

      &:hover,
      &:focus {
        .btn {
          background-color: $brand-list;
        }
      }
    }
  }

  &.quick-link-screen {
    &:before {
      background-image: url('../img/quick-links/screen.jpg');
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('../img/quick-links/screen@2x.jpg');
      }
    }

    a {
      h3 {
        color: $brand-screen;
      }

      &:hover,
      &:focus {
        .btn {
          background-color: $brand-screen;
        }
      }
    }
  }

  &.quick-link-lease {
    &:before {
      background-image: url('../img/quick-links/lease.jpg');
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('../img/quick-links/lease@2x.jpg');
      }
    }

    a {
      h3 {
        color: $brand-lease;
      }

      &:hover,
      &:focus {
        .btn {
          background-color: $brand-lease;
        }
      }
    }
  }

  &.quick-link-collect {
    &:before {
      background-image: url('../img/quick-links/collect.jpg');
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('../img/quick-links/collect@2x.jpg');
      }
    }

    a {
      h3 {
        color: $brand-collect;
      }

      &:hover,
      &:focus {
        .btn {
          background-color: $brand-collect;
        }
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    &:after {
      content: '';
      display: block;
      padding-bottom: 64.04494382%;
    }

    a {
      height: 100%;
      position: absolute;
      width: 100%;

      h3 {
        font-size: 40px;
      }

      p {
        font-size: 18px;
      }

      .btn {
        font-size: 16px;
        line-height: 1.33333;
        padding: 10px 24px;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    &:after {
      padding-bottom: 45.064377682%;
    }

    a {
      p {
        margin-bottom: 34px;
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    &:after {
      padding-bottom: 89.285714286%;
    }

    a {
      p {
        margin-bottom: 52px;
      }
    }
  }
}
