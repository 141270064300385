.post-listing {

  .post-listing-inner {
    padding: 0 40px;
  }

  @media (max-width: $screen-sm-min) {
    .post-listing-inner {
      padding: 0 10px;
    }
  }

  .sub-heading {
    color: $brand-success;
    font-size: 18px;
    font-weight: bold;
  }

  .copy-link {
    color: $brand-primary;
    cursor: pointer;

    &.copied {
      color: $brand-success;
    }
  }

  .listing-options {
    margin: 40px 0;

    .listing-item {
      background-color: #fff;
      border-bottom: 2px solid $gray-lightest;
      padding: 20px 0;
      transition: background 0.5s linear;

      img {
        background-color: #fff;
        max-width: 140px;
        padding: 10px;
      }

      p {
        padding: 10px;
      }

      @media (max-width: $screen-sm-min) {
        p {
          margin-top: 10px;
        }
      }      

      &:last-child {
        border-bottom: none;
      }

      .listing-label {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0;
        width: 70%;
      }

      &.new-listing-item {
        background-color: lighten($brand-info, 30%);        
      }
    }
  }
}