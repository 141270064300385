.terms {
  @media (max-width: $screen-xs-max) {
    font-size: 14px;
  }

  h2:first-child {
    display: none;
  }

  .uno {
    color: $brand-success;
    font-family: $headings-font-family;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;

    @media (min-size: $screen-sm-min) {
      font-size: $font-size-h4;
    }
  }

  .dos {
    font-weight: bold;
    text-transform: uppercase;
  }

  .tres {
    text-decoration: underline;
  }

  ol {
    list-style: none !important;
    margin-left: 0;
    padding-left: 0;

    li {;
      margin-left: 0;
      padding-left: 0;
      position: relative;

      @media (min-width: $screen-sm-min) {
        padding-left: 34px;
      }

      &:before {
        font-weight: bold;

        @media (min-width: $screen-sm-min) {
          display: block;
          position: absolute;
          left: 0;
          text-align: right;
          width: 26px;
        }
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  & > ol {
    counter-reset: level-one-counter;
    margin-bottom: $line-height-computed*1.5;
    margin-top: $line-height-computed*1.5;

    & > li {
      &:before {
        color: $brand-success;
        content: counter(level-one-counter) '. ';
        counter-increment: level-one-counter;
        font-family: $headings-font-family;
        font-size: 18px;

        @media (min-size: $screen-sm-min) {
          font-size: $font-size-h4;
        }
      }

      &:not(:first-child) {
        margin-top: $line-height-computed*1.5;
      }

      li {
        margin-top: $line-height-computed/2;
      }

      & > ol {
        counter-reset: level-two-counter;

        & > li {
          &:before {
            content: counter(level-two-counter, upper-alpha) '. ';
            counter-increment: level-two-counter;
          }

          & > ol {
            counter-reset: level-three-counter;

            & > li {
              &:before {
                content: counter(level-three-counter, lower-roman) '. ';
                counter-increment: level-three-counter;
              }

              & > ol {
                counter-reset: level-four-counter;

                @media (max-width: $screen-xs-max) {
                  padding-left: 20px;
                }

                & > li {
                  &:before {
                    content: counter(level-four-counter, lower-alpha) '. ';
                    counter-increment: level-four-counter;
                  }

                  & > ol {
                    counter-reset: level-five-counter;

                    @media (max-width: $screen-xs-max) {
                      padding-left: 20px;
                    }

                    & > li {
                      &:before {
                        content: counter(level-five-counter, upper-roman) '. ';
                        counter-increment: level-five-counter;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
