.actions {
  font-size: 0;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;

    @media (min-width: $screen-sm-min) {
      &:not(:first-child) {
        margin-left: 5px;
      }
    }

    @media (min-width: $screen-md-min) {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  a {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    vertical-align: baseline;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    white-space: nowrap;
    @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
    @include user-select(none);

    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);

    border-color: $brand-primary;

    &,
    &:active,
    &.active {
      &:focus,
      &.focus {
        @include tab-focus;
      }
    }

    &:hover,
    &:focus,
    &.focus {
      background-color: #fff;
      color: $btn-default-color;
      text-decoration: none;
    }

    &:active,
    &.active {
      outline: 0;
      background-image: none;
      @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
      cursor: $cursor-disabled;
      @include opacity(.65);
      @include box-shadow(none);
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }

    @media (min-width: $screen-sm-min) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (min-width: $screen-md-min) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .data-grid-body & a {
    vertical-align: baseline;
  }

  .action-danger a:not(:hover):not(:focus) {
    border-color: $brand-danger;
  }

  .action-success a:not(:hover):not(:focus) {
    border-color: $brand-success;
  }
}
