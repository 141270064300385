  .pdf-viewer {
    background-color: $gray-lighter;
    min-height: 930px;
    padding: 2px 0;

    @media(min-width: $screen-md-min) {
      min-height: 1160px;
      padding: 12px 0;
    }

    @media(min-width: $screen-lg-min) {
      min-height: 1280px;
      padding: 18px 0;
    }

    canvas {
      box-shadow: 0 0 10px rgba(0,0,0,.3);
      display: block;
      margin: 18px auto;
      max-width: 90%;

      @media(min-width: $screen-md-min) {
        margin-top: 36px;
        margin-bottom: 36px;
      }
    }
  }
