.details {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 70px;
  }

  .container > &:first-child {
    margin-top: 18px;
  }

  @media (min-width: $screen-lg-min) {
    margin-bottom: 45px;

    &:last-child {
      margin-bottom: 70px;
    }

    .container > &:first-child {
      margin-top: 43px;
    }
  }

  header {
    margin-bottom: 18px;

    h2 {
      line-height: 1.125;
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;

      @media (min-width: $screen-lg-min) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .h2-next {
      line-height: 36px;
    }
  }
}

.details-summary {
  background-color: $gray-lighter;
  margin-bottom: 20px;
  padding: 9px 10px;

  @media(max-width: $screen-md-max) {
    &.details-summary-no-header {
      padding-top: 17px;
    }
  }

  @media (min-width: $screen-lg-min) {
    margin-bottom: 40px;
    padding: 24px 20px 17px;
  }

  h3 {
    font-size: 26px;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 14px;

    small {
      font-size: $font-size-base;

      @media print {
        display: none;
      }
    }

    @media(min-width: $screen-sm-min) {
      margin-bottom: $line-height-computed;
    }
  }

  & + .alert {
    margin-bottom: 20px;

    @media (min-width: $screen-lg-min) {
      margin-bottom: 40px;
    }
  }
}

.details-link {
  padding-left: 36px;
  position: relative;

  > .glyphicon {
    display: block;
    font-size: 130%;
    left: 1px;
    position: absolute;
    top: 2px;
  }

  > a {
    color: $gray-dark;
    text-decoration: underline;
  }
}

.details-actions {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: $line-height-computed/2;
  }

  a {
    display: block;
    font-weight: $btn-font-weight;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    white-space: nowrap;
    width: 100%;
    @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
    @include user-select(none);

    &,
    &:active,
    &.active {
      &:focus,
      &.focus {
        @include tab-focus;
      }
    }

    &:hover,
    &:focus,
    &.focus {
      color: $btn-default-color;
      text-decoration: none;
    }

    &:active,
    &.active {
      outline: 0;
      background-image: none;
      @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
      cursor: $cursor-disabled;
      @include opacity(.65);
      @include box-shadow(none);
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }

    &.details-action-primary {
      @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    }

    &.details-action-warning {
      @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
    }
  }

  @media print {
    display: none;
  }
}
