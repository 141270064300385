.login-page {
	height: 100%;

	.login-form {
		background: white;
		margin-top: 15px;
		padding: 1px 23px 13px;

		&:before {
			content: '';
	    display: block;
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    max-width: 386px;
	    height: 7px;
	    background: #34a247;
	    background: linear-gradient(to right,#34a247,#bbd94a);
		}

		h3 {
			color: $brand-success;
			font-weight: bold;
			margin-bottom: $line-height-computed;
			margin-top: $line-height-computed + 7;
			text-transform: uppercase;
		}

		label {
			display: block;
		}

		.login-btn {

			label {
				display: inline-block;
			}

			.btn {
				display: block;
				margin: 10px 0 15px;
				width: 100%;

				@include button-variant($btn-success-color, $btn-success-bg, transparent);

				&:hover,
				&:focus {
					border-color: transparent;
				}
			}

			& > .checkbox:first-child {
				margin-top: 0;
			}

			& > .checkbox + .btn {
				margin-top: 15px;
			}
		}

		.expire {
			color: $text-muted;
			font-size: $font-size-small;
			line-height: $line-height-large;
			text-align: center;
		}
	}

	.logo {
		text-align: center;
		padding: 20px;
	}

	.cite-login {
		color: $gray;
		text-align: center;
		font-weight: bold;

		cite {
			font-style: normal;
			text-transform: uppercase;
		}
	}
}
