.email-preview {
  background-color: #efefef;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.email-container {
  min-width: 628px;
  padding: 20px;

  & > div {
    background-color: #fff;
    margin: 0 auto;
    padding: 30px 24px 44px;
    width: 588px;
  }
}

.email-preview-responsive {
  background-color: $gray-lighter;
}

.email-container-responsive {
  @media only screen and (max-width: 602px) {
    .small-float-center {
      margin: 0 auto!important;
      float: none!important;
      text-align: center!important;
    }

    table.body img {
      width:auto;
      height:auto;
    }

    table.body center{
      min-width: 0 !important;
    }

    table.body .container{
      width: 95% !important;
    }

    table.body .columns {
      height: auto !important;
      box-sizing: border-box;
      padding-left: 16px!important;
      padding-right: 16px!important;
    }

    th.small-12 {
      display: inline-block!important;
      width: 100%!important;
    }

    table.menu {
      width: 100%!important;
    }

    table.menu td,
    table.menu th {
      width: auto !important;
      display: inline-block !important;
    }

    table.menu.vertical td,
    table.menu.vertical th {
      display: block !important;
    }

    .spacer20 td{
      font-size: 10px !important;
      line-height: 10px !important;
      height: 10px !important;
    }

    .spacer50 td {
      font-size: 25px !important;
      line-height: 25px !important;
      height: 25px !important;
    }
  }

  @media all{
    .text-notalink a {
      color: #333;
      text-decoration: none;
    }

    .text-success.text-notalink a {
      color: #98c32d;
    }

    table.button.primary:active table td,table.button.primary:hover table td,
    table.button.primary:visited table td {
      background: #3072a9 !important;
      color: #fff;
    }

    table.button.primary:active table a,
    table.button.primary:hover table a,
    table.button.primary:visited table a {
      border: 0 solid #3072a9 !important;
    }

    table.button.secondary:hover table td {
      background: #d5d5d5 !important;
      color: #fff;
    }

    table.button.secondary:hover table a {
      border: 0 solid #d5d5d5 !important;
    }

    table.button.success:hover table td {
      background: #789a23 !important;
    }

    table.button.success:hover table a {
      border: 0 solid #789a23 !important;
    }

    .main h1,
    .main h2 {
      font-family: $headings-font-family !important;
    }
  }

}
