.summary {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0 0 $line-height-computed/2 5px;

  @media (min-width: $screen-sm-min), print {
    justify-content: center;
    padding-left: 0;
  }

  > li {
    background-color: $gray-light;
    border-radius: $panel-border-radius;
    color: #fff;
    display: block;
    flex: none;
    font-family: $headings-font-family;
    margin: 0 5px $line-height-computed/2;
    text-align: center;
    width: calc(50% - 12.5px);

    &.summary-neg {
      background-color: $brand-danger;
    }

    &.summary-pos {
      background-color: $brand-success;
    }

    @media(min-width: $screen-sm-min) {
      width: 132px;
    }

    @media(min-width: $screen-md-min) {
      width: 172px;
    }

    @media(min-width: $screen-lg-min) {
      margin-left: 10px;
      margin-right: 10px;
      width: 198px;
    }

    @media print {
      display: inline-block;
      vertical-align: top;
      width: 1.25in;
    }
  }

  .summary-value,
  .summary-label {
    padding-left: $panel-padding-horizontal;
    padding-right: $panel-padding-horizontal;
  }

  .summary-value {
    display: block;
    font-size: 46px;
    font-weight: bold;
    line-height: 1.2;

    @media(min-width: $screen-lg-min) {
      font-size: 60px;
    }

    @media print {
      font-size: 36px;
    }
  }

  .summary-description {
    display: table;
    width: 100%;
  }

  .summary-label {
    display: table-cell;
    font-size: 14px;
    height: 48px;
    line-height: 1.1;
    overflow: hidden;
    padding-bottom: 11px;
    vertical-align: middle;
    width: 100%;

    @media(min-width: $screen-lg-min) {
      font-size: 20px;
      height: 62px;
      padding-bottom: 14px;
    }

    @media print {
      font-size: 12px;
    }
  }

  @media print {
    display: block;
    page-break-inside: avoid;
    text-align: center;
  }
}
