/* palanquin-100 - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-weight: 100;
  src: local('Palanquin Thin'), local('Palanquin-Thin'),
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-300 - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-weight: 300;
  src: local('Palanquin Light'), local('Palanquin-Light'),
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-regular - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-weight: 400;
  src: local('Palanquin Regular'), local('Palanquin-Regular'),
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-200 - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-weight: 200;
  src: local('Palanquin ExtraLight'), local('Palanquin-ExtraLight'),
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-500 - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-weight: 500;
  src: local('Palanquin Medium'), local('Palanquin-Medium'),
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-600 - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-weight: 600;
  src: local('Palanquin SemiBold'), local('Palanquin-SemiBold'),
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-700 - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-weight: 700;
  src: local('Palanquin Bold'), local('Palanquin-Bold'),
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
       url('../fonts/palanquin-v1-latin/palanquin-v1-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
