.alert {
  margin-top: $line-height-computed;

  &.alert-no-mg-top {
    margin-top: 0;
  }

  &.alert-success.alert-dismissible .close {
    color: #8da485;
    opacity: 1;
  }

  .alert-icon {
    padding-left: 0;
    padding-right: 10px;
    position: relative;

    > h4 {
      margin-bottom: $line-height-computed;
      margin-left: 40px;
    }

    .close {
      right: -10px;
    }

    & > .glyphicon {
      display: block;
      font-size: 24px;
      left: 2px;
      top: 4px;
      position: absolute;
    }

    @media(min-width: $screen-xs-min) {
      padding-left: 40px;
      padding-right: 30px;

      > h4 {
        margin-bottom: $line-height-computed/2;
        margin-left: 0;
      }

      .close {
        right: -30px;
      }
    }
  }

  .alert-link {
    color: inherit;
    text-decoration: underline;

    &.alert-link-normal {
      font-weight: 400;
    }
  }

  .alert-link-muted {
    color: inherit;
  }

  p + ol,
  p + ul {
    margin-top: $line-height-computed/2;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

.alert-system {
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);

  border-radius: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 1000;

  .alert-dismissible {
    padding-left: 40px;
    padding-right: 20px;
    position: relative;
  }

  .alert-link {
    color: inherit;
    white-space: nowrap;
  }

  .glyphicon-alert {
    display: block;
    font-size: 24px;
    left: 0;
    top: 4px;
    position: absolute;
  }

  h4 {
    margin-bottom: 4px;
  }

  p {
    margin-bottom: 4px;
  }
}

.page-main > .container > .alert:first-child {
  margin-top: 25px;

  &.not-animated {
    transform: translateY(-200px) scale(1.25);
    transition: transform 1.5s cubic-bezier(.87,-.81,.19,1.84);

    &.bounceInDown {
      transform: translateY(0) scale(1);
    }
  }

  &.animated {
    &.bounceInDown {
      animation: bounceInDown 1.5s cubic-bezier(.87,-.81,.19,1.84);
    }
  }

  @media (min-width: $screen-sm-min) {
    margin-bottom: -25px;
  }
}

@keyframes bounceInDown {
  0%   { transform: translateY(-200px) scale(1.25) }
  100% { transform: translateY(0)      scale(1)    }
}
