.dropdown-menu {
  > li {
    > a {
      transition: background-color .5s, color .5s;

      &.disabled,
      &[disabled] {
        color: $text-muted;
        cursor: $cursor-disabled;
        pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
      }

      &.menu-callout {
        color: $link-color;

        &:focus,
        &:hover {
          background-color: transparent;
          text-decoration: underline;
        }
      }
    }

    > .menu-help {
      display: block;
      padding: 3px 20px 0;
      clear: both;
      font-weight: normal;
      line-height: $line-height-base;
      color: $dropdown-link-color;
      white-space: nowrap; // prevent links from randomly breaking onto new lines

      & + .menu-callout {
        padding-top: 0;
      }
    }
  }
}
