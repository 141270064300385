$credit-card-height: 38px;

.credit-cards {
  @extend .clearfix;

  min-width: 255px;

  ul {
    float: left;
    height: $credit-card-height;
    list-style: none;
    margin: 3px 0 26px;
    padding: 0;
  }

  li {
    @extend .text-hide;

    backface-visibility: hidden;
    background: url('../img/payment/credit-card-sprite.png') no-repeat;
    float: left;
    height: $credit-card-height;
    padding: $credit-card-height 0 0;
    transition: opacity ease-out 1s;
    width: 60px;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url('../img/payment/credit-card-sprite@2x.png');
      background-size: 255px $credit-card-height;
    }

    &:not(:first-child) {
      margin-left: 5px;

      @media(min-width: $screen-xs-min) {
        margin-left: 10px;
      }
    }

    &.not-selected {
      opacity: 0.2;
    }

    &.amex {
      background-position: 0 0;
    }

    &.discover {
      background-position: -65px 0;
    }

    &.mastercard {
      background-position: -130px 0;
    }

    &.visa {
      background-position: -195px 0;
    }
  }
}
