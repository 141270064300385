.page-footer {
  background-color: $gray-base;
  color: $gray;
  font-size: 14px;
  padding: 30px 0 40px;
  text-align: center;

  ul {
    list-style: none;
    font-size: 0;
    margin: 0;
    padding: 0;

    li {
      display: inline;
    }

    a {
      color: $brand-success;
      display: inline-block;
      font-size: 14px;
      padding: $nav-link-padding;
      text-decoration: none;
    }

    a:hover,
    a:active,
    a:focus,
    .active a {
      text-decoration: underline;
    }

    & + p {
      margin-top: 14px;
    }
  }

  @media print {
    display: none;
  }
}
