/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request: h5bp.com/r
// ==========================================================================

@media print {
    *,
    *:before,
    *:after {
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; // h5bp.com/t
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    // Bootstrap specific changes start

    // Bootstrap components
    .navbar-inverse {
      background-color: #fff;
    }

    .navbar-toggle,
    .navbar-nav,
    .navbar-collapse.collapse {
        display: none !important;
    }
    .btn,
    .dropup > .btn {
        > .caret {
            border-top-color: #000 !important;
        }
    }
    .label {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;

        td,
        th {
            background-color: #fff !important;
        }
    }
    .table-bordered {
        th,
        td {
            border: 1px solid #ddd !important;
        }
    }

    // Bootstrap specific changes end

    // Print grid

    [class*='col-'],
    [class*='col-'] {
      float: left;
    }

    @for $i from 1 through 12 {
      .col-sm-#{$i}:not([class*='col-md']):not([class*='col-print']) {
        width: percentage(($i / $grid-columns));
      }
    }

    @include make-grid('print');

    // Custom print styles

    body {
      background-color: #fff;
      font-size: 9pt;
    }

    .container {
      width: auto;
    }

    .text-sm-center {
      text-align: center;
    }

    .text-sm-right {
      text-align: right;
    }

    .hint {
      display: none;
    }

    .visible-xs-inline,
    .visible-xs-block,
    .visible-xs-inline-block,
    .visible-sm-inline,
    .visible-sm-block,
    .visible-sm-inline-block,
    .print-hide {
      display: none !important;
    }

    .print-block {
      display: block !important;
      float: none !important;
      position: static !important;
      width: 100% !important;
    }

    .print-inline {
      display: inline !important;
    }

    .print-inline-block {
      display: inline-block !important;
    }

    .page-main > .container > section:first-child header h2:not(.print-hide) {
      string-set: pagetitle content();
    }

    // Page Setup

    @page {

      size:8.5in 11in;
      margin: .625in .5in .5in;

      @bottom-left {
        content: 'LeaseRunner ' string(pagetitle);
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
      }

      @bottom-right {
        content: counter(page);
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
      }

    }
}

@media screen {

  .screen-hide {
    display: none;
  }

}
