.accordion {

  fieldset,
  .fieldset {
    &.panel {
      background-color: transparent;;
      border: 0 none;
      border-radius: 0;
      box-shadow: none;
    }
    &.panel:not(.panel-mg-bottom) {
      margin-bottom: 0;
    }
  }

  legend,
  .legend {
    user-select: none;

    .caret {
      margin-left: 0;
      transition: transform .25s;
      transform: rotate(0);
    }

    &.collapsed {
      margin-bottom: 0;

      .caret {
        transform: rotate(-90deg);
      }

    }

  }

  [role="tabpanel"] {

    & > .well:first-child {
      border-radius: 0 0 $border-radius-base $border-radius-base;
    }

    & > *:last-child {
     margin-bottom: 0;
   }

  }

}

.accordion-processing {
  display: block !important;
  height: 0;
  overflow: hidden;
}
