.form-group {
  .character-counter {
    font-size: 12px;
    font-style: italic;
    line-height: 2.166666667;

    @media(min-width: $screen-sm-min) {
      float: right;
    }
  }

  .checkbox:last-child,
  .checkbox ~ .help-block,
  .radio:last-child,
  .radio ~ .help-block {
    margin-bottom: $line-height-computed*1.25;
  }
}

.form-control {
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    color: $input-color-placeholder;
  }
}

textarea.form-control {
  @for $i from 2 through 9 {
    &[rows="#{$i}"] {
      height: ( ceil( $font-size-base * $line-height-base ) * $i ) + ($padding-base-vertical * 2);
    }
  }
}

.control-label {
  display: block;
  margin-bottom: 5px;
  padding-right: 30px;

  & ~ br {
    display: none;
  }

  & ~ [data-toggle="popover"] {
    right: 0;
    position: absolute;
    top: 0;
  }

  & + .checkbox {
    margin-top: 3px;
  }

  & + .btn:not(.btn.btn-text-xxs) {
    line-height: $line-height-base;
  }

  @media (min-width: $screen-sm-min) {
    display: inline-block;
    padding-right: 0;

    & ~ br {
      display: inline;
    }

    & ~ [data-toggle="popover"] {
      position: static;
    }
  }
}

.control-warning {
  display: block;
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
  margin: -5px 0 5px;
  white-space: nowrap;

  @media(min-width: $screen-sm-min) {
    display: inline;
    margin: 0 0 0 7px;
  }
}

@mixin feedback-position($rows:1, $offset:0) {
  $muliplier: if( $offset > 0, $rows, $rows - 1 );
  bottom: ( ceil( $font-size-base * $line-height-base ) * $muliplier ) + $offset;
}

.has-feedback {

  label {
    & ~ .form-control-feedback {
      visibility: hidden;
    }
  }

  .hasAddonAfter {
    ~ span.form-control-feedback {
      right: 40px;
    }
  }

  &.has-success label,
  &.has-error label {
    & ~ .form-control-feedback {
      top: auto;
      visibility: visible;
    }

    & ~ select.form-control {
      padding-right: $padding-base-horizontal;

      & ~ .form-control-feedback {
       right: 24px;
     }
    }
  }

  &.has-success label {
    & ~ .form-control-feedback {
      @include feedback-position(1);
    }

    & ~ textarea {
      @for $i from 2 through 9 {
        &[rows="#{$i}"] ~.form-control-feedback {
          @include feedback-position($i);
        }
      }
    }
  }

  &.has-error label,
  &.has-help label {
    & ~ .form-control-feedback {
       @include feedback-position(1, 5);
    }

    & ~ textarea {
      @for $i from 2 through 9 {
        &[rows="#{$i}"] ~.form-control-feedback {
          @include feedback-position($i, 5);
        }
      }
    }
  }

  .form-control-feedback.loading:before {
    color: $brand-info;
    content: '\e023';
  }

  .datepicker + .form-control-feedback {
    right: 40px;
  }

}

.has-success {
  .form-control {
    border-color: $input-border;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));

    &:focus {
      @include form-control-focus;
    }
  }

  .input-group-addon {
    border-color: $input-group-addon-border-color;
    background-color: $input-group-addon-bg;
    color: $input-color;
  }

  .checkbox,
  .checkbox-inline,
  .control-label,
  .radio,
  .radio-inline,
  .checkbox label,
  .checkbox-inline label,
  .radio label,
  .radio-inline label {
    color: $text-color;
  }

  .form-control-feedback,
  .help-block {
    color: $brand-success;
  }
}

.has-error {
  .checkbox,
  .checkbox-inline,
  .radio,
  .radio-inline,
  .checkbox label,
  .checkbox-inline label,
  .radio label,
  .radio-inline label {
    color: $text-color;
  }

  .datepicker {
    button.btn {
      border-color: $state-danger-text;
    }
  }
}

fieldset {
  .alert:first-child {
    margin-top: 4px;
  }
}

.agree {
  float: left;

  & + .btn {
    float: right;

    @media(min-width: $screen-xs-min) {
      float: none;
      margin-left: 30px;
    }
  }
}

.help-block[id*="parsley"] {
  margin: 0;

  &.filled {
    margin-top: 5px;
    margin-bottom: 10px;
    overflow: hidden;
  }
}

.radio input[type="checkbox"],
.radio-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}

.disabled-warning {
  &[data-toggle=popover] {
    position: static;
  }

  &:focus {
    outline: none;
  }

  [disabled] {
    pointer-events: none;
  }
}
