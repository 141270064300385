.data-grid {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 70px;
  }

  .container > &:first-child {
    margin-top: 18px;
  }

  @media (min-width: $screen-lg-min) {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 70px;
    }

    .container > &:first-child {
      margin-top: 43px;
    }
  }

  header {
    margin-bottom: 18px;

    h2 {
      line-height: 1.125;
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;

      @media (min-width: $screen-lg-min) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    & + .alert {
      margin-bottom: 18px;
      margin-top: 0;
      padding-left: 10px;
      padding-right: 10px;

      &.alert-dismissible {
        padding-right: 31px;

        &.text-center {
          padding-left: 31px;
        }
      }

      .alert-icon {
        padding-right: 9px;
      }

      @media (min-width: $screen-lg-min) {
        padding-left: 20px;
        padding-right: 20px;

        &.alert-dismissible {
          padding-right: 41px;

          &.text-center {
            padding-left: 41px;
          }
        }
      }
    }
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;

    @media (min-width: $screen-lg-min) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  [class*='col-'] {
    padding-left: 10px;
    padding-right: 10px;

    @media (min-width: $screen-lg-min) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  > .well {
    margin-bottom: 34px;
    padding-left: 10px;
    padding-right: 10px;

    @media (min-width: $screen-lg-min) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  footer {
    margin-top: $line-height-computed;
    padding: 0 10px;

    .pagination {
      margin: 0 -10px;
    }

    p {
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
      padding: 6px 0;
    }

    @media (min-width: $screen-lg-min) {
      padding-left: 20px;
      padding-right: 20px;

      .pagination {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.data-grid-body {
  display: table;
  width: 100%;

  .row {
    display: table-row;

    &:before {
      display: none;
    }

    & > [class*='col-'] {
      display: table-cell;
      float: none;
      padding-bottom: 6px;
      padding-top: 8px;
    }
  }

  .data-grid-header {
    background-color: $gray;
    color: #fff;
    font-weight: bold;

    & > [class*='col-'] {
      background-color: $gray;
      vertical-align: middle;
    }

    a {
      color: #fff;
      display: block;
      outline: none;
      position: relative;

      &:after {
        color: $gray-light;
        content: '\e260';
        font-family: 'Glyphicons Halflings';
        font-size: 75%;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        position: absolute;
        top: 50%;
        transition: color .5s;
        transform: translate(10px, -50%);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &.desc:after {
        content: '\e259';
      }

      &.active,
      &:hover,
      &:focus {
        color: #fff;
        text-decoration: none;

        &:after {
          color: #fff;
        }
      }
    }
  }

  .close {
    color: $gray;
    display: block;
    float: none;
    opacity: 1;
    text-align: center;
    transition: color .5s;
    width: 100%;

    &:hover,
    &:focus {
      color: $brand-danger;
    }

    &:focus {
      outline: none;
    }
  }

  .btn-group {
    width: 100%;
  }

  .btn.btn-default.btn-xs.dropdown-toggle {
    font-weight: normal;
    padding-right: 18px;
    text-align: left;

    &:hover,
    &:focus {
      background-color: #fff;
    }

    .caret {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.zebra {
    .row:first-child:not(.data-grid-header) ~ .row:nth-child(even),
    .data-grid-header ~ .row:nth-child(odd) {
      background-color: $gray-lighter;
    }
  }

  &.first-col-zebra {
    border-collapse: collapse;

    .row-wrap {
      display: table-row-group;

      [class*='col-']:first-child {
        background-color: $gray-lighter !important;
        color: #888;

        strong {
          color: #000;
        }
      }

      &:nth-child(odd) {
        [class*='col-']:first-child {
          background-color: $gray-lightest !important;
        }
      }

      [class*=col-] {
        transition: background-color .5s;
      }

      &.data-grid-highlighted {

        .row,
        [class*=col-]:first-child {
          background-color: $brand-success !important;
        }

        &,
        * {
          color: #fff !important;
          transition: color 0s !important;
        }
      }
    }

    .row {

      &:not(.data-grid-header) {
        [class*='col-'] {
          border-top: 1px solid $gray;
        }
      }

      &:last-child [class*='col-'] {
        border-bottom: 1px solid $gray;
      }
    }
  }

  .units {
    [class*='col-'] {
      border-top: 1px dashed #555 !important;
    }

    [class*='col-']:first-child {
      text-align: right;
      border-top: none !important;
    }
  }

  .action-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &.mg-top {
    margin-top: 22px;
  }
}

.no-properties {
  display: table-cell;
  column-span: 5;
}
