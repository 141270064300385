
.vantagescore {
  height: 0;
  margin: $line-height-computed/3 0 $line-height-computed;
  padding: 0;
  padding-bottom: 23.364485981%;
  position: relative;
  width: 100%;

  @media (min-width: $screen-md-min), print {
    margin: 0;
  }

  > svg {
    left: 0;
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.vantagescore-pointer {
  fill: #fff;
  stroke: #fff;
  stroke-linejoin: round;
  stroke-width: 6px;

  @media (min-width: $screen-md-min), print {
    stroke: $gray-lighter;
  }
}

.vantagescore-number {
  fill: $gray-base;
  font-family: Palanquin,sans-serif;
  font-size: 100px;
  font-weight: bold;

  @media(min-width: $screen-sm-min) {
    font-size: 72px;
  }

  @media (min-width: $screen-md-min), print {
    font-size: 50px;
  }
}

.vantagescore-range > text,
.vantagescore-label > text {
  display: none;
  font-family: Palanquin,sans-serif;
  font-size: 16px;
  font-weight: bold;

  @media (min-width: $screen-md-min), print {
    display: block;
  }
}

.vantagescore-range > text {
  fill: #fff;
}

.vantagescore-label > text {
  fill: $gray;
  text-transform: uppercase;

  &.vantagescore-bad {
    fill: $brand-danger;
  }

  &.vantagescore-excellent {
    fill: $brand-success;
  }

  &.vantagescore-active {
    display: block;
    fill: $gray-base;
    font-size: 44px;

    &.vantagescore-danger {
      fill: $brand-danger;
    }

    &.vantagescore-warning {
      fill: $brand-warning;
    }

    &.vantagescore-success {
      fill: $brand-success;
    }

    @media (min-width: $screen-md-min), print {
      display: none;
    }
  }
}
