.navbar {
  margin-bottom: 0;

  a {
    transition: color .5s;
  }
}

.navbar-static-top {
  border-style: none;
  border-width: 0;

  .navbar-brand {
    height: auto;
    padding-bottom: 22px;
    padding-top: 22px;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 16px;
      padding-top: 30px;
    }
  }

  .navbar-toggle {
    margin-top: 29px;
  }
}

.navbar-nav-meta > li > a {
  font-size: 14px;
  margin-top: 5px;
  text-transform: uppercase;

  .navbar-inverse & {
    color: $gray-light;

    &:hover,
    &:focus {
      color: #fff;
    }

    &:focus {
      outline: none;
    }
  }
}

.navbar-nav-lg {
  margin-bottom: 0;

  > li > a {
    font-weight: bold;
  }

  @media(min-width: $screen-sm-min) {
    > li > a {
      &:focus {
        outline: none;
      }
    }

    > li:first-child > a {
      padding-left: 0;
    }
  }

  @media (min-width: $screen-md-min) {
    > li > a {
      font-size: 18px;
      padding-left: 30px;
      padding-right: 30px;

      &:focus {
        outline: none;
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    > li > a {
      font-size: 20px;
    }
  }
}

.navbar-nav {
  > li > a.navbar-highlight,
  > li > a.navbar-highlight:hover {
    background-color: $gray-dark;
    color: $gray-light;
    font-weight: normal;

    strong {
      color: #fff;
      font-weight: bold;
      transition: color .5s;
    }

    &:hover strong,
    &:focus strong {
      color: $gray-light;
    }
  }

  & + .navbar-collapse {
    clear: both;
  }
}

.navbar-inverse {
  .navbar-form {
    border: 0 none;
    box-shadow: none;

    .form-control {
      background-color: $gray-dark;
      border-color: $gray-dark;
      color: #fff;

      &:focus {
        box-shadow: none;
        outline: none;
      }

      @include placeholder($gray-light);
    }

    .btn {
      background-color: $gray-dark;
      border-color: $gray-dark;
      color: $gray-light;

      &:hover,
      &:focus {
        color: #fff;
      }

      &:focus {
        outline: none;
      }
    }

    @media (min-width: $screen-lg-min) {
      & .form-control {
        width: 320px;
      }
    }
  }
}

.navbar-toggle {
  span:not(.sr-only) {
    transition: all .3s;
  }

  span:nth-of-type(2) {
    transform: rotate(45deg);
    transform-origin: 17.33% 222.67%;
  }

  span:nth-of-type(4) {
    transform: rotate(-45deg);
    transform-origin: 17.33% -77.33%;
  }

  span:nth-of-type(3) {
    opacity: 0;
  }

  &.collapsed {
    span:nth-of-type(2),
    span:nth-of-type(4) {
      transform: rotate(0);
    }

    span:nth-of-type(3) {
      opacity: 1;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .container > .navbar-collapse {
    margin-left: -15px;
    margin-right: -15px;

    .navbar-form {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
