$panel-padding-horizontal: 15px;

.panel {
  > .table {
    > thead {
      > tr {
        > th {
          padding-left: $panel-padding-horizontal;
          padding-right: $panel-padding-horizontal;
        }
      }

      @media only screen and (max-width: $screen-xs-max) {
        display: none;

        & ~ tbody > tr:first-child {
          & > th,
          & > td {
            border-top: 0 none;
          }
        }
      }
    }
    > tbody {
      > tr {

        > th,
        > td {
          padding-left: $panel-padding-horizontal;
          padding-right: $panel-padding-horizontal;
        }

        > th:first-child {

          @media only screen and (max-width: $screen-xs-max) {
            display: block;
            padding-bottom: 0;

            & ~ td {
              border-top: 0 none;
              display: block;
              padding-top: 0;
            }

            & ~ td:not(:last-child) {
              padding-bottom: 0;
            }
          }

          @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            width: 40%;
          }

          @media(min-width: $screen-md-min), print {
            width: 36%;
          }

        }

        > td:first-child {
          @media only screen and (max-width: $screen-xs-max) {
            display: block;
            padding-bottom: 0;

            & ~ td {
              border-top: 0 none;
              display: block;
              padding-top: 0;
            }

            & ~ td:not(:last-child) {
              padding-bottom: 0;
            }
          }
        }

      }
    }
    > tfoot {
      > tr {
        > th,
        > td {
          padding-left: $panel-padding-horizontal;
          padding-right: $panel-padding-horizontal;
        }

        > th:first-child {
          @media only screen and (max-width: $screen-xs-max) {
            display: block;
            padding-bottom: 0;

            & ~ td {
              border-top: 0 none;
              display: block;
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  .panel-body {
    > .row:last-child {
      .list-inline {
        margin-bottom: 0;
      }
    }
    > .row-border + .row-border {
      border-top: 1px solid $table-border-color;
      padding-top: $panel-body-padding;
    }
  }

  > .list-group {
    margin-left: 0;
  }

  + .footnote {
    margin-top: -$line-height-computed/2;
  }

  @media print {
    page-break-inside: avoid;
  }

}

.panel-dark {
  @include panel-variant($gray, #fff, $gray, $gray);

  .list-group-item {
    border-color: $gray;
  }

  .panel-footer {
    background-color: #fff;
    border-color: $gray;
  }
}
