.modal-dialog {
  @media (max-width: $screen-xs-max) {
    margin: 15px;
  }
}

.modal-lg {
  @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
    margin-left: 30px;
    margin-right: 30px;
    width: auto;
  }
}

.modal-content {
  border-width: 5px;

  &.modal-list {
    border-color: $brand-list;

    .modal-title {
      color: $brand-list;
    }
  }

  &.modal-screen {
    border-color: $brand-screen;

    .modal-title {
      color: $brand-screen;
    }
  }

  &.modal-lease {
    border-color: $brand-lease;

    .modal-title {
      color: $brand-lease;
    }
  }

  &.modal-collect {
    border-color: $brand-collect;

    .modal-title {
      color: $brand-collect;
    }
  }
}

.modal-header {
  .close {
    margin-top: 7px;
  }
}

.modal-title {
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;

  &.modal-title-sm {
    font-size: 24px;
    line-height: 1;

    @media (min-width: $screen-sm-min) {
      font-size: 36px;
      line-height: 1.25;
    }
  }

  @media (max-width: $screen-xs-max) {
    &.modal-title-shrink-xs {
      font-size: 28px;
      line-height: 1;
    }
  }
}

.modal-body {
  .alert:first-child {
    margin-top: 4px;
  }

  &:not(:last-child) {
    .alert:last-child {
      margin-bottom: 15px;
    }
  }
}

.modal-footer {
  padding-bottom: 18px;

  @media(min-width: $screen-sm-min) {
    .btn.btn-link:first-child {
      padding-left: 0;
    }
  }
}
