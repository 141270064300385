.form-main {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 70px;
  }

  .container > &:first-child {
    margin-top: 18px;
  }

  @media (min-width: $screen-lg-min) {
    margin-bottom: 45px;

    &:last-child {
      margin-bottom: 70px;
    }

    .container > &:first-child {
      margin-top: 43px;
    }

    &.form-main-pad-right {
      padding-right: 55px;
    }
  }

  header {
    margin-bottom: 18px;

    h2 {
      line-height: 1.125;
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;

      @media (min-width: $screen-lg-min) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    & + .alert {
      margin-bottom: 18px;
      margin-top: 0;
      padding-left: 10px;
      padding-right: 10px;

      &.alert-dismissible {
        padding-right: 31px;

        &.text-center {
          padding-left: 31px;
        }
      }

      .alert-icon {
        padding-right: 9px;
      }

      @media (min-width: $screen-lg-min) {
        padding-left: 20px;
        padding-right: 20px;

        &.alert-dismissible {
          padding-right: 41px;

          &.text-center {
            padding-left: 41px;
          }
        }
      }
    }
  }

  > .alert {

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  > h4:first-child {
    margin-top: 22px;
  }

  .legend {
    margin-top: 0;
    margin-bottom: 22px;
  }

  .legend-no-mg {
    margin: 0;
  }

  legend, .legend {
    background-color: $gray-lighter;
    border-bottom: 2px solid $brand-success;
    color: $legend-color;
    display: block;
    font-family: $headings-font-family;
    font-size: 20px;
    font-weight: 700;
    line-height: $line-height-base;
    padding: 4px 10px 5px;
    text-decoration: none;
    text-transform: uppercase;

    &:focus {
      outline: none;
    }

    @media (min-width: $screen-lg-min) {
      padding-left: 20px;
      padding-right: 20px;
    }

    &.icon {
      padding-left: 50px;
      position: relative;

      &:before {
        background-color: $brand-success;
        bottom: -2px;
        color: white;
        content: '';
        display: block;
        left: 0;
        font-family: 'Glyphicons Halflings';
        font-size: 22px;
        font-style: normal;
        font-weight: normal;
        line-height: 1.727272727;
        position: absolute;
        text-align: center;
        top: 0;
        width: 38px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &.icon-info:before {
        content: '\e086';
      }

      &.icon-additional:before {
        content: '\e056';
      }

      &.icon-rental:before {
        content: '\e021';
      }

      &.icon-employment:before {
        content: '\e118';
      }

      &.icon-notes:before {
        content: '\e065';
      }

      &.icon-certify:before {
        content: '\e013';
      }

      &.icon-usd:before {
        content: '\e148';
      }

      &.icon-sign:before {
        content: '\270f';
      }
    }

    & ~ .alert {
      margin-top: 4px;
      padding-left: 10px;
      padding-right: 10px;

      @media (min-width: $screen-lg-min) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &.dark {
      background-color: $gray-dark;
      border-color: $gray-dark;
      color: #fff;
    }

    @media print {
      page-break-before: always;
    }
  }

  fieldset {
    & + fieldset {
      margin-top: 22px;
    }
  }

  .fieldset__fields {
    padding-left: 10px;
    padding-right: 10px;

    @media (min-width: $screen-lg-min) {
      padding-left: 20px;
      padding-right: 20px;

      .row {
        margin-left: -15px;
        margin-right: -15px;
      }

      [class*='col-'] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .subset {
    border-bottom: 3px solid $gray-base;
    border-top: 3px solid $gray-base;
    margin: 0 0 32px;
    padding: 22px 0 15px;
  }

  .form__actions {
    border-top: 4px solid $gray-lighter;
    margin-top: 22px;
    padding-top: 20px;
    text-align: right;

    .btn + .btn {
      margin-left: 5px;
      margin-bottom: 0;
    }
  }

  .panel-body &,
  .tab-pane & {
    margin-bottom: 0;
  }

  .form-group ~ .toggle-field {
    margin-bottom: $line-height-computed * 1.25;
    margin-top: -$line-height-computed;
  }
}
