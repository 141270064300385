h3,
h3 {
  &.h3-no-mg-top {
    margin-top: 0;
  }

  & + .progress {
    margin-top: $line-height-computed*1.25;
  }
}

h4,
.h4 {
  font-weight: 200;

  &.h4-mg-bottom {
    margin-top: 0;
  }

  &.h4-no-mg {
    margin: 0;
  }

  &.h4-top-mg {
    margin-top: 20px;
  }

  .glyphicon:first-child {
    margin-right: 3px;
  }

  small {
    font-family: $font-family-sans-serif;

    .btn-link {
      padding-left: 0;
      padding-right: 0;
    }

    &:before {
      content: '\00a0';
    }

    @media only screen and (max-width: $screen-sm-max) {
      &.block-sm {
        display: block;

        &:before {
          content: '';
          display: none;
        }
      }
    }
  }
}

.heading-underline {
  border-bottom: 1px solid #000;
  padding-bottom: 11px;
}

p {
  &.mg-lg {
    margin-bottom: $line-height-computed;
  }

  &.mg-lg-top {
    margin-bottom: $line-height-computed;
    margin-top: $line-height-computed;
  }

  &.mg-lg-top-only {
    margin-top: $line-height-computed;
  }
}

ol {
  &.ol-btm-mg {
    margin-bottom: 20px;
  }

  &.ol-lg-lh {
    line-height: 1.75;
  }
}

ul {
  &.mg-none {
    margin: 0;
  }

  &.ul-btm-mg {
    margin-bottom: $line-height-computed;
  }
}

.text-color-primary {
  color: $brand-primary;
}

.text-color-success {
  color: $brand-success;
}

.text-color-info {
  color: $brand-info;
}

.text-color-warning {
  color: $brand-warning;
}

.text-color-danger {
  color: $brand-danger;
}

.text-color-progress {
  color: $brand-progress;
}

.text-color-gray {
  color: $gray;
}

.text-var {
  color: #0000ff;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.text-big {
  font-size: larger;
}

.text-size-default {
  font-size: $font-size-base;
}

@media (max-width: $screen-xs-max) {
  .text-xs-center {
    text-align: center;
  }
}

@media (min-width: $screen-sm-min) {
  .text-sm-center {
    text-align: center;
  }
}

@media (min-width: $screen-sm-min) {
  .text-sm-right {
    text-align: right;
  }
}

@media (min-width: $screen-md-min) {
  .text-md-right {
    text-align: right;
  }
}

.page-header {
  padding-bottom: 0;
  margin: 0;
  border-bottom: 0 none;
}

.dl-horizontal {
  dd {
    margin-bottom: ($line-height-computed / 2);

    @media (min-width: $dl-horizontal-breakpoint) {
      margin-bottom: 0;
    }
  }

  dt {
    text-align: left;
  }
}

.dl-row {
  padding-bottom: $line-height-computed;

  dl {
    margin-bottom: 0;
  }
}

.h2-note, .label-inline-msg {
  font-size: 12px;
  font-style: italic;
  margin-top: $line-height-computed/2;
  margin-bottom: 0;
  padding: 0 10px;

  @media(min-width: $screen-sm-min) {
    margin-top: 19px;
    text-align: right;
  }
}

.label-inline-msg {
  margin-bottom: -19px;
  margin-top: 20px;
}

.force-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.footnote-reference {
  font-size: smaller;
  font-weight: normal;
  vertical-align: top;
}

.text-nowrap {
  .glyphicon {
    display: inline;
  }
}

.sub-nav-list {

  li {
    margin: 5px 0;

    &.active {
      a {
        color: #000;
        font-weight: bold;
      }
    }
  }

}

.minitron {
  border: 5px solid $gray-lightest;
  color: $gray;
  font-family: $font-family-sans-serif;
  font-size: $font-size-h1;
  font-weight: bold;
  padding: $padding-large-vertical * 4;
}
