
.highcharts {
  height: 300px;
  margin-bottom: $line-height-computed;
  margin-top: $line-height-computed * 1.5;

  @media(min-width: $screen-sm-min) {
    height: 400px;
  }

  @media(min-width: $screen-md-min) {
    height: 500px;
  }

  @media(min-width: $screen-lg-min) {
    height: 600px;
  }

  @media print {
    height: auto;
  }
}

.highcharts-sm {
  height: 210px;
  margin-bottom: $line-height-computed;
  margin-top: $line-height-computed * 1.5;

  h4 + &,
  .h4 + & {
    margin-top: $line-height-computed;
  }

  @media(min-width: $screen-sm-min) {
    height: 270px;
  }

  @media(min-width: $screen-md-min) {
    height: 330px;
  }

  @media(min-width: $screen-lg-min) {
    height: 390px;
  }

  @media print {
    height: auto;
  }
}
