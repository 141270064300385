.screening-total {
  border: 6px solid $gray-lighter;
  color: $gray;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
  padding: 10px 10px 11px;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: $screen-md-min) {
    font-size: 36px;
    padding-bottom: 31px;
    padding-top: 30px;
  }

  .amount {
    color: $brand-success;
    margin-left: 18px;
  }
}
