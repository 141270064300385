.sample {
  margin-bottom: 70px;

  header {
    margin-bottom: 18px;
    margin-top: 18px;

    @media (min-width: $screen-lg-min) {
      margin-top: 43px;
    }

    h2 {
      line-height: 1.125;
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;

      @media (min-width: $screen-lg-min) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .h2-next {
      line-height: 36px;
    }
  }
}

.img-sample {

  margin: 0 auto;

  picture {
    display: block;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  @media(min-width: $screen-xs-min) {
    width: 375px;
  }

  @media(min-width: $screen-sm-min) {
    width: 576px;
  }

  @media(min-width: $screen-md-min) {
    width: 992px;
  }

  @media(min-width: $screen-lg-min) {
    width: 1200px;
  }
}
